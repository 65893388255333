import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import React, { useEffect } from 'react';
import Logo from 'assets/images/HotelX-Logo.png'
import Background from 'assets/images/HR-Background.jpg'
import { Card, CardContent, Grid, useMediaQuery } from '@material-ui/core';
import { useGetFolioInfoQuery } from 'generated/graphql';
import { amtStr } from 'containers/helper/numFormatter';
import theme from '@ifca-root/react-component/src/assets/theme';
import { useSnackBar } from 'containers/helper/hooks/useSnackBar';
import SnackBarMsg from 'components/SnackBar/SnackBarMsg';

const dateFormat = require('dateformat')

export const EinvoiceQr = props => {
    
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
    
    const url = new URL(window.location.href);
    const segments = url.pathname.split('/');

    const HotelID = segments[2]; 
    const DocType = segments[3];
    const FolioID = segments[4];

    console.log(url,segments,"storedFolio")

const { snackFunc, close, snackBarMessage, openSnackBar } = useSnackBar()

const { data: { getFolioInfo } = { getFolioInfo: {} } } = useGetFolioInfoQuery({
  variables: { 
    HotelID: HotelID, 
    DocType: DocType, 
    FolioID: FolioID 
  },
  fetchPolicy: 'network-only',
  onCompleted: data =>{
    if ( data.getFolioInfo?.QrCodeUrl !== null){
                window.location.href = data.getFolioInfo?.QrCodeUrl
            }
  },
  onError: error => {
    error.graphQLErrors.map(({ message }) => {
      snackFunc(message, false)

    })
  },
});

    console.log(getFolioInfo,'getFolioInfo')

    return (
			<AuthLayout logo={Logo} image={Background}>
			<br></br>

        <Card>
					<span
							style={{
									fontFamily: 'Poppins, sans-serif',
									fontWeight: 700,
									fontSize: '24px',
									display: 'flex', 
									justifyContent: 'center',
									padding: '12px 0',
							}}
					>
							e-Invoice Request
					</span>
            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '12px'}}>
                <Card
                    style={{
                        backgroundColor: '#f0f0f0',
                        borderRadius: '8px', 
                        width: isDesktop ? '70%' : '95.5%',
                    }}
                >
									<CardContent >
											<Grid container>
													<Grid item xs={6} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	Document No
															</div>
													</Grid>
													<Grid item xs={1} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	:
															</div>
													</Grid>
													<Grid item xs={5} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	{getFolioInfo?.DocNo}
															</div>
													</Grid>
											</Grid>
											<Grid container>
													<Grid item xs={6} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	Document Date
															</div>
													</Grid>
													<Grid item xs={1} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	:
															</div>
													</Grid>
													<Grid item xs={5} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	{dateFormat(getFolioInfo?.DocDate,'dd/mm/yyyy')}
															</div>
													</Grid>
											</Grid>
											<Grid container>
													<Grid item xs={5} style={{ textAlign: 'left' }}>
															{' '}
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
															
															</div>
													</Grid>
											</Grid>
											<Grid container>
													<Grid item xs={6} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	Amount
															</div>
													</Grid>
													<Grid item xs={1} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	:
															</div>
													</Grid>
													<Grid item xs={5} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	{amtStr(getFolioInfo?.DocAmt)}
															</div>
													</Grid>
											</Grid>
											<Grid container>
													<Grid item xs={6} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	Status
															</div>
													</Grid>
													<Grid item xs={1} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	:
															</div>
													</Grid>
													<Grid item xs={5} style={{ textAlign: 'left' }}>
															<div
																	style={{
																			fontSize: '12px',
																			fontWeight: 400,
																			lineHeight: '20px',
																	}}
															>
																	{getFolioInfo?.DocStatus}
															</div>
													</Grid>
											</Grid>
									</CardContent>
                </Card>
            </div>
        </Card>
        <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </AuthLayout>
    )
  
}
